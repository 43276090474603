<template>
  <div id="ForgetPassword">
    <!-- 头部组件 -->
    <headPageNew />
    <div class="container">
      <div class="loginControlForget">
        <div class="loginFormForget">
          <div class="loginContent">
            <div class="loginTitle">
              <p style="font-size: 26px">重置密码</p>
            </div>
            <div class="loginInput">
              <div>
                <el-form
                  ref="ruleForm"
                  :model="ruleForm"
                  :rules="rules"
                  label-width=""
                  label-position="right"
                  class="elForm"
                >
                  <el-form-item prop="mobile">
                    <el-input
                      type="text"
                      v-model="ruleForm.mobile"
                      size="small"
                      clearable
                      placeholder="请输入手机号"
                    />
                  </el-form-item>
                  <el-form-item prop="code">
                    <el-input
                      v-model="ruleForm.code"
                      type="text"
                      size="small"
                      placeholder="请输入验证码"
                    >
                    </el-input>
                    <div
                      class="yzmz"
                      :style="codeDisabled ? 'pointer-events: none;' : ''"
                      @click="getCode"
                    >
                      {{ codeMsg }}
                    </div>
                  </el-form-item>
                  <el-form-item prop="adminName">
                    <el-input
                      type="text"
                      autocomplete="new-password"
                      v-model="ruleForm.adminName"
                      clearable
                      size="small"
                      placeholder="请输入登录帐号"
                    />
                  </el-form-item>
                  <el-form-item prop="newPassword">
                    <el-input
                      type="password"
                      autocomplete="new-password"
                      v-model="ruleForm.newPassword"
                      clearable
                      size="small"
                      placeholder="密码由8-20位且小写字母、大写字母、数字、特殊符号中的三种组合"
                    />
                  </el-form-item>
                  <el-form-item prop="performPassword">
                    <el-input
                      type="password"
                      autocomplete="new-password"
                      v-model="ruleForm.performPassword"
                      clearable
                      size="small"
                      placeholder="请再次输入新密码"
                    />
                  </el-form-item>
                  <el-form-item>
                    <div class="loginButtonForget">
                      <el-button style="background: #f2f5f9" @click="cancelBack"
                        >取消</el-button
                      >
                      <el-button class="bgc-bv" @click="resetPassword2"
                        >确认重置密码</el-button
                      >
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部组件 -->
    <bottomPage />
  </div>
</template>

<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "../wrongAuthenticatePage/bottomPage.vue";
export default {
  name: "ForgetPassword",
  components: {
    headPageNew,
    bottomPage,
  },
  data() {
    //密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback("请输入新密码");
      } else {
        let result = this.checkPassword(value);
        if (!result.flag) {
          callback(new Error(result.msg));
        } else {
          callback();
        }
      }
    };
    let validateNewPass = (rule, value, callback) => {
      if (
        this.ruleForm.newPassword &&
        value.trim() != this.ruleForm.newPassword.trim()
      ) {
        callback(new Error("请检查两次输入的新密码是否相同"));
      } else {
        callback();
      }
    };
    return {
      // 公共方法
      ruleForm: {
        mobile: "", //手机号
        adminName: "", //登录帐号
        code: "", //验证码
        newPassword: "",
        performPassword: "",
      },
      rules: {
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        adminName: [
          { required: true, message: "请输入登录帐号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        performPassword: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          { validator: validateNewPass, trigger: "blur" },
        ],
      },
      codeMsg: "获取验证码",
      auth_time: "180",
      codeDisabled: false,
    };
  },
  created: function () {},
  methods: {
    checkPassword(str) {
      //校验密码，密码长度8-20，必须包含大写字母、小写字母、数字、特殊符号中的三种
      let rC = {
        lW: "[a-z]",
        uW: "[A-Z]",
        nW: "[0-9]",
        sW: "[\\u0020-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]",
      };
      function Reg(str, rStr) {
        let reg = new RegExp(rStr);
        if (reg.test(str)) return true;
        else return false;
      }
      if (str.length < 8) {
        return { flag: false, msg: "密码长度不得少于8位" };
      } else if (str.length > 20) {
        return { flag: false, msg: "密码长度不得超过20位" };
      } else {
        let tR = {
          l: Reg(str, rC.lW),
          u: Reg(str, rC.uW),
          n: Reg(str, rC.nW),
          s: Reg(str, rC.sW),
        };
        if (
          (tR.l && tR.u && tR.n) ||
          (tR.l && tR.u && tR.s) ||
          (tR.s && tR.u && tR.n) ||
          (tR.s && tR.l && tR.n)
        ) {
          return { flag: true, msg: "" };
        } else {
          return {
            flag: false,
            msg: "密码需由  小写字母、大写字母、数字、特殊符号  中的任意三种组成",
          };
        }
      }
    },
    //获取验证码
    getCode() {
      this.$refs.ruleForm.validateField("mobile", (phoneError) => {
        if (!phoneError) {
          this.auth_time = 180;
          this.codeDisabled = false;
          this.$post("/sys/sms/login/captcha", { mobile: this.ruleForm.mobile })
            .then((ret) => {
              this.getAuthCode();
              this.$confirm("验证码已发送至手机号上，请查收。", {
                confirmButonText: "确定",
                center: true,
                confirmButtonClass: "bgc-bv",
              });
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    //验证码倒计时
    getAuthCode() {
      if (this.authTimeTimer) {
        clearTimeout(this.authTimeTimer);
      }
      this.authTimeTimer = setTimeout(() => {
        this.auth_time -= 1;
        this.codeMsg = this.auth_time + "s";
        this.codeDisabled = true;
        if (this.auth_time < 0) {
          this.codeDisabled = false;
          this.codeMsg = "获取验证码";
          clearTimeout(this.authTimeTimer);
        } else {
          this.getAuthCode();
        }
      }, 1000);
    },
    //确认重置密码
    resetPassword2() {
      const ruleForm = this.$refs.ruleForm;
      ruleForm.validate((valid) => {
        // console.log(valid);
        if (valid) {
          // console.log("55555");
          this.$post("/sys/admin/forgetPassword", {
            mobile: this.ruleForm.mobile,
            code: this.ruleForm.code,
            adminName: this.ruleForm.adminName,
            newPassword: this.ruleForm.newPassword,
            performPassword: this.ruleForm.performPassword,
          }).then((res) => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.$refs.ruleForm.resetFields();
              this.cancelBack();
            }
          });
        }
      });
    },
    cancelBack() {
      this.$router.push({
        path: "/web/login",
        query: {
          loginShowFlag: 'true',
          loginShowStu: 'false'
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#ForgetPassword {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f8fafd;

  .container {
    flex: 1;
    width: 100%;
    padding: 60px 0 90px;
    background: #F9FAFC;
    .loginControlForget {
      width: 500px;
      margin: 0 auto;
      .loginTitle {
        text-align: center;
        padding: 30px 0 20px;
        font-size: 34px;
      }
      .el-form-item__label {
        font-size: 17px;
        color: #3f4652;
      }
      .loginButtonForget {
        padding: 1.25rem 0 0;
        display: flex;
        text-decoration: none;
        > .el-button {
          width: 100%;
          height: 2.5rem;
        }
        .bgc-bv{
          background: #2878FF !important;
          border-color: #2878FF !important;
          &:hover{
            background: #2878FF !important;
            border-color: #2878FF !important;
          }
        }
      }
    }
    .elForm {
      box-shadow: 0px 6px 50px 1px #eeeeee;
      padding: 40px 40px 10px 40px;
      border-radius: 6px;
      .yzmz {
        position: absolute;
        top: 0;
        right: 20px;
        color: #2878ff;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>